/* Navbar.css */

.header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	/* background-color: transparent; */
	background-color: var(--nav-bg);
	z-index: var(--z-fixed);
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
}

.nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	height: var(--header-height);
	margin: 1rem;
}

.nav__logo {
	color: var(--first-color);
	transition: color 0.4s;
	font-size: calc(var(--h2-font-size));
	font-family: var(--second-font);

	text-transform: uppercase;
}

.nav__logo img {
	width: 130px;
}

.nav__toggle,
.nav__close {
	font-size: 1.5rem;
	color: var(--body-color);
	cursor: pointer;
}

.nav__cta {
	/* background-image: linear-gradient(to right,  rgba(205, 198, 74, 0.913), rgba(21, 40, 145, 0.93));; */
	background: var(--button-color);
	color: var(--title-color) !important;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	padding: 0.75rem 1.5rem;

	/* background-color: var(--lemon-yellow); */
}
.nav__cta.active {
	color: var(--title-color);
}

@media screen and (max-width: 1150px) {
	.nav__menu {
		position: fixed;
		top: 0;
		right: -100%;
		/* background-color: hsla(0, 0%, 6%, 0.2);   commented out because HSA said otherwise*/
		background-color: hsla(0, 0%, 100%, 0.2);
		backdrop-filter: blur(16px);
		-webkit-backdrop-filter: blur(16px);
		width: 80%;
		height: 100%;
		padding: 6rem 3rem 0;
		transition: right 0.4s;
	}
}
@media screen and (max-width: 300px) {
	.nav__menu {
		padding: 4rem 1rem 2rem;
	}

	.nav__list {
		row-gap: 1rem;
	}

	.nav__link,
	.nav__cta {
		font-size: var(--smaller-font-size);
	}

	.nav__cta {
		padding: 8px 16px;
		font-size: 12px;
		margin-top: 1rem;
		width: auto;
		white-space: nowrap;
	}
}

.nav__list {
	display: flex;
	flex-direction: column;
	row-gap: 2.5rem;
}

.nav__link {
	color: var(--body-color);
	font-weight: var(--font-semi-bold);
	transition: color 0.4s;
}

.nav__link:hover {
	color: #c8a503;
}

.active {
	color: var(--lemon-yellow);
}

.nav__cta:hover {
	transition: opacity 0.5s ease-in-out;
	/* background-image: linear-gradient(
		to right,
		rgba(21, 40, 145, 0.93),
		rgba(205, 198, 74, 0.913)
	); */
	opacity: 90%;
	box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
	color: var(--title-color);
}

.nav__close {
	position: absolute;
	top: 1rem;
	right: 1.5rem;
}

.show-menu {
	right: 0;
}
.nav__listt {
	display: flex;
	flex-direction: row;
	column-gap: 2.5rem;
}

.nav__menu_mobile {
	position: fixed;
	top: 0;
	right: -100%;
	background-color: hsla(0, 0%, 6%, 0.2);
	backdrop-filter: blur(16px);
	-webkit-backdrop-filter: blur(16px);
	width: 80%;
	height: 100%;
	padding: 6rem 3rem 0;
	transition: right 0.4s;
}
@media screen and (min-width: 1150px) {
	.nav {
		height: calc(var(--header-height) + 1rem);
	}
	.nav__toggle,
	.nav__close {
		display: none;
	}

	.nav__list {
		flex-direction: row;
		column-gap: 2.5rem;
	}
}
