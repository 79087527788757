.main-content {
    padding-top: 6rem;
    /* padding-left: 1rem; */
  }


  /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--body-color);
  transition: background .3s ease;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000f9;
}


.des{
  margin: 1rem 0;
  line-height: 24px;
  color: var(--body-color);
}

@media screen and (max-width: 450px) {
  .des{
				font-size: 0.875rem !important;
		line-height: 20px !important;
	}
}

.media-appearance-title{
	margin: 0px 0px 10px 2rem;
	margin-right: 2rem;
	color: #232536;
}

.media-appearance-des{
  margin: 1rem 0;
  line-height: 24px;
  color: var(--body-color);
}