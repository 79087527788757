/* tablet */
@media screen and (max-width: 768px) {
	.main-content {
		padding-top: 5rem !important;
	}

	/* -------------- home page ----------------  */

	/* home hero */
	.hero .hero-text {
		max-width: 90% !important;
	}

	.hero-text h1 {
		font-size: 30px !important;
	}

	.hero-text p {
		font-size: 12px !important;
	}

	.hero-text button {
		padding: 15px 30px !important;
		font-size: 15px !important;
		font-weight: 700 !important;
	}
	/* hero achievements */
	.achievements-container {
		padding: 0 !important;
		height: 200px !important;
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
	}

	.achievements-container .achievements-content {
		width: 100% !important;
	}

	.achievements-content h5 {
		font-size: 18px !important;
	}

	.achievements-content .achievement-img-container img {
		width: 600px !important;
	}

	/* home about */
	.home-about {
		padding: 0px 10px !important;
		flex-direction: column;
		align-items: center !important;
	}

	.home-about .img-container {
		width: 100% !important;
	}

	.home-about .content {
		width: 100% !important;
	}

	/* home blog */
	.home-blog-container {
		max-width: 100% !important;
		padding: 2rem 0 !important;
		margin-bottom: 0 !important;
		flex-direction: column;
	}

	.home-blog-container .featured-post-container {
		width: 100% !important;
	}

	.home-blog-container .all-post-container {
		width: 100% !important;
	}

	.inner-all-post-container .box {
		/* background-color: #f5f5f582; */
		margin-bottom: 1rem !important;
		background-color: #f5f5f5c9 !important;
		border-radius: 10px !important;
		transition: transform 0.2s ease;
	}

	.inner-all-post-container .box:active {
		transform: scale(99%) !important;
	}

	/* newsletter */
	.newsletter_container {
		max-width: 95% !important;
		padding: 50px 35px !important;
		/* margin:  auto !important; */
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.newsletter_container .title {
		font-size: 35px !important;
	}

	.events-header p {
		font-size: 14px !important;
	}

	.event-card {
		width: 50% !important;
	}

	.events-list {
		gap: 1rem !important;
		flex-direction: column;
		align-items: center !important;
		justify-content: center !important;
	}

	/* footer */
	.footer-container .footer-header .links {
		display: none !important;
	}

	.footer-container .second_box {
		padding: 3rem 0 !important;
	}

	.footer-container .second_box .subscribe_box {
		flex-direction: column;
		gap: 1rem !important;
		align-items: unset !important;
	}

	.footer-container .second_box .registration_box .email_box {
		width: 90% !important;
	}

	/* -------------- about page ----------------  */

	.about-content .title {
		font-size: 30px !important;
		line-height: initial !important;
	}

	.about-content .small {
		font-size: 18px !important;
	}

	.about-content {
		top: 60% !important;
		line-height: initial !important;
	}

	.vission_mission_container {
		padding-top: 0 !important;
		flex-direction: column !important;
		max-width: 100% !important;
	}

	.vission_mission_container .mission_container {
		padding: 1rem !important;
	}

	.vission_mission_container .title {
		font-size: 25px !important;
	}

	.vission_mission_container .vission_container {
		padding: 1rem !important;
	}

	.biography_container {
		max-width: 95% !important;
		margin-top: 2rem !important;
	}

	.bio-image {
		float: right !important;
		width: 350px !important;
		margin: 0px 0px 10px 15px !important;
	}

	.content .title {
		line-height: unset !important;
	}

	/* services page */
	.services-hero-content .title {
		font-size: 30px !important;
		line-height: initial !important;
	}

	.services .services-hero-content {
		top: 60% !important;
	}

	.consulting-container {
		max-width: 90% !important;
		padding: 0 !important;
	}

	.consulting-container .flex {
		flex-direction: column;
	}

	.consulting-container .title {
		margin-bottom: 1rem !important;
		font-size: 30px;
	}

	.consulting-container .even_title {
		margin-left: 40% !important;
	}

	.image_container {
		width: 90% !important;
	}

	/* resources */
	.resources-hero-content .title {
		font-size: 30px !important;
		line-height: unset !important;
	}

	.resources .resources-hero-content {
		top: 60% !important;
	}

	/* the rest of the resources page is styled with mui */

	/* community */
	.community .community-hero-content {
		top: 60% !important;
	}
	.community-hero-content .title {
		font-size: 30px !important;
		line-height: unset !important;
	}

	.welcome-container {
		max-width: 100% !important;
		margin: unset;
		padding: 1rem 0;
		flex-direction: column !important;
	}

	.welcome-container .welcome-text {
		text-align: justify;
		width: 100% !important;
	}

	.welcome-container .welcome-picture {
		display: none;
	}

	.initiatives_container {
		flex-direction: row-reverse;
		max-width: 100% !important;
	}

	.initiatives_container .initiatives_container_img {
		width: unset !important;
	}

	.initiatives_container_text .title {
		font-size: 25px;
	}

	/* contanct section  */
	.contact-us-container {
		max-width: 90% !important;
	}

	.form_info_container {
		flex-direction: column-reverse !important;
		gap: 2rem !important;
	}

	.contact-us-container h1 {
		display: none;
	}

	.contact-us-container .desc {
		display: none;
	}

	.contact-us-container .info-card {
		flex-direction: unset !important;
		justify-content: space-between !important;
	}

	.contact-us-container .info-card {
		width: 100% !important;
	}
	.form-container {
		width: 100% !important;
	}

	/* blog post */
	.full-page{
		max-width: 90% !important;
	}
}

/* big mobile screen */

@media screen and (max-width: 430px) {
	.hero {
		background-position: right !important;
		background-attachment: unset !important;
	}

	.hero .hero-text {
		padding: 0rem 10px !important;
		max-width: unset !important;
		text-align: center !important;
	}

	.hero-text h1 {
		font-size: 40px !important;
	}

	.hero-text #hero-to-be-hidden {
		display: none;
	}

	.hero-text p {
		font-size: 0.9rem !important;
		color: rgb(227, 227, 227) !important;
		line-height: 1.6;
	}

	.achievements-container .achievements-content {
		padding: 10px !important;
	}

	.achievements-content h5 {
		font-size: 0.9rem !important;
	}

	.achievements-content .achievement-img-container img {
		width: 300px !important;
	}

	.home-about .content h1 {
		font-size: 35px !important;
	}

	.home-blog-container .title {
		font-size: 30px !important;
	}

	.box .title {
		font-size: 20px !important;
	}

	.newsletter_container .title {
		font-size: 30px !important;
	}

	.testimonial-container {
		max-width: 100% !important;
		padding: 1rem !important;
	}

	.testimonial-container h2 {
		font-size: 25px !important;
	}

	.newsletter_container .newsletter-text {
		font-size: 0.9rem !important;
	}

	.newsletter-form .newsletter-btn {
		font-size: 0.8rem !important;
		padding: 0.75rem 1.2rem !important;
	}

	.socialMedia_container {
		padding: 1rem !important;
	}

	.socialMedia_container .icons {
		max-width: 100% !important;
		gap: 1rem !important;
	}

	.socialMedia_container .social_icon {
		font-size: 35px !important;
	}

	.upcoming-events {
		padding: 2rem 0;
	}
	.events-header h2 {
		font-size: 25px !important;
	}

	.events-header p {
		font-size: 0.9rem !important;
	}

	.event-card {
		width: 90% !important;
	}

	.footer-container .second_box .subscribe_content {
		font-size: 25px !important;
		line-height: 1.5 !important;
	}

	.footer-container .last_box .value_africa {
		gap: 1rem !important;
	}

	.css-19rj2l0 {
		padding: 1rem 0 !important;
	}

	/* .css-1qe6o3f-MuiCardContent-root{
        padding: 0 !important;
    } */

	/* about page */
	.bg {
		background-position: right !important;
		background-attachment: unset !important;
	}
	.about-content {
		width: 100% !important;
		padding: 0rem 1rem !important;
	}

	.about-content .small {
		font-size: 0.9rem !important;
		margin-bottom: 10px !important;
	}

	.about-content .title {
		font-size: 25px !important;
		line-height: 1.6 !important;
	}z

	.vission_mission_container .small {
		font-size: 0.9rem !important;
	}

	.vission_mission_container .mission_container {
		gap: 0.7rem !important;
	}
	.vission_mission_container .title {
		font-size: 20px !important;
	}

	.vission_mission_container .vission_container {
		gap: 0.7rem !important;
	}
	.biography-background {
		background-image: linear-gradient(
				180deg,
				rgba(15, 15, 15, 0.9) 0%,
				rgb(15, 15, 15) 100%
			),
			url(../Assets/about-big-bg_1.webp) !important;
	}
	.bio-image {
		float: unset !important;
		margin: 0 !important;
		width: 100% !important;
		/* border-radius: unset; */
	}
	.content .title {
		/* text-align: center; */
	}
	.content .description {
		text-align: unset !important;
	}

	/* services section */
	.services .services-bg {
		background-position: right !important;
		background-attachment: unset !important;
	}

	.services .services-hero-content {
		width: 100% !important;
		padding: 0rem 1rem !important;
	}

	.services-hero-content .small {
		font-size: 0.9rem !important;
		margin-bottom: 10px !important;
	}
	.services-hero-content .title {
		font-size: 25px !important;
		line-height: 1.6 !important;
	}

	.consulting-container {
		margin: 1rem auto !important;
		margin-bottom: 3rem !important;
	}

	.consulting-container .title {
		font-size: 22px !important;
	}

	.consulting-container .flex {
		flex-direction: column-reverse !important;
	}

	.consulting-container .speaking-flex {
		/* background-color: rebeccapurple !important; */
		flex-direction: column !important;
	}
	.consulting-container .even_title {
		margin-left: auto !important;
		margin-right: 15% !important;
	}
	.flex .descri {
		font-size: 0.9rem;
	}

	.services-list .icon {
		margin-top: 0.4rem !important;
		font-size: 1rem !important;
	}

	.services-list p {
		font-size: 0.9rem !important;
	}

	.image_container {
		width: 100%;
	}

	/* resources section */
	.resources .resources-bg {
		background-position: left !important;
		background-attachment: unset !important;
	}

	.resources .resources-hero-content {
		width: 100% !important;
		padding: 0rem 1rem !important;
	}

	.resources-hero-content .small {
		font-size: 0.9rem !important;
		margin-bottom: 10px !important;
	}

	.resources-hero-content .title {
		font-size: 25px !important;
		line-height: 1.6 !important;
	}

	/* all post */
	.css-ng1ds1 {
		max-width: 100% !important;
		padding: 1rem !important;
	}

	.all-post-title {
		font-size: 25px !important;
		margin-bottom: 1rem !important;
	}

	/* publications */
	.publication-title {
		font-size: 25px !important;
		margin-bottom: 1rem !important;
	}

	/* community page */
	.community .community-bg {
		background-position: right !important;
		background-attachment: unset !important;
	}

	.community .community-hero-content {
		width: 100% !important;
		padding: 0rem 1rem !important;
	}

	.community-hero-content .small {
		font-size: 0.9rem !important;
		margin-bottom: 10px !important;
	}

	.community-hero-content .title {
		font-size: 25px !important;
		line-height: 1.6 !important;
	}

	.welcome-container {
		padding: 1rem !important;
	}

	.welcome-text .title {
		font-size: 25px !important;
		font-weight: 500 !important;
	}

	.welcome-text .description {
		font-size: 0.9rem !important;
		text-align: start !important;
	}

	.initiatives_container {
		padding: 1rem !important;
		flex-direction: column !important;
		margin-bottom: 2rem !important;
	}

	.initiatives_container_img img {
		width: 80% !important;
	}

	.initiatives_container_text .title {
		/* font-size: 20px !important; */
	}

	.initiatives_container_text .description {
		font-size: 0.9rem !important;
	}

	.agid-flex {
		flex-direction: column-reverse !important;
	}

	/* contact section */
	.contact-us-container {
		margin-top: 2rem !important;
		margin-bottom: 2rem !important;
	}

	.contact-us-container .info-card {
		flex-direction: column !important;
	}

	.full-page{
		margin-top: 2rem !important;
	}

	.full-page .top-writing{
		font-size: 30px !important;
	}

	.full-page .second-writing{
		font-size: 1rem !important;
		line-height: 28px !important;
	}
	
	.full-page .content{
		font-size: 1rem;
		line-height: 30px !important;
		text-align: start !important;
	}
}


/* media appearances media quert */

@media screen and (max-width: 450px) {
	.media-appearance-title{
		font-size: 25px !important;
		margin-left: 1rem;
	}
	
	.media-appearance-des{
		margin-left: 1rem !important;
		font-size: 0.875rem !important;
		line-height: 20px !important;
	}
}