@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
	outline: none;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	transition: all ease-in;
}
html {
	scroll-behavior: smooth;
	overflow-x: hidden;
}

:root {
	--header-height: 3rem;
	--first-color: #000034;
	--lemon-yellow: #c8a503;
	--title-color: #fff;
	--text-color: hsl(0, 0%, 75%);
	--body-color: hsl(0, 0%, 2%);
	--body-font: "Fira Sans", sans-serif;
	--second-font: "Dancing Script", cursive;
	--h2-font-size: 1.25rem;
	--small-font-size: 0.813rem;
	--font-semi-bold: 600;
	--z-tooltip: 10;
	--z-fixed: 100;
	/* --button-color: #7a6800; this is the prevous color*/  
	--button-color: #C8A500; /* this is the yellow color HSA suggested */

	--nav-bg: #fff;
}

body {
	/* background-color: var(--body-color); */
	color: var(--text-color);
	font-family: var(--body-font);
	overflow-x: hidden !important;
}

ul {
	list-style: none;
}

a {
	text-decoration: none;
}

.mb {
	margin-bottom: 3rem;
}

.mt {
	margin-top: 3rem;
}
